import _ from 'lodash';
import nxModule from 'nxModule';
import PrintService from 'print/PrintService';
import './passbook.style.less';
import templateUrl from './passbook.template.html';

const printService = new PrintService();

class Passbook {
  showPrinted = false;

  constructor($location, $filter, printsCache, modalPrintPreviewService, operationService, operationTableBuilder, http, command, $route, operationCodeFormatter) {
    this.$location = $location;
    this.$filter = $filter;
    this.modalPrintPreviewService = modalPrintPreviewService;
    this.operationService = operationService;
    this.tableBuilder = _.cloneDeep(operationTableBuilder); // for OperationTableBuilder to not share field values with txn history component
    this.http = http;
    this.command = command;
    this.$route = $route;
    this.operationCodeFormatter = operationCodeFormatter;
    this.prints = null;
    this.printLoader = printsCache.toPromise().then(prints => this.prints = prints);
  }

  $onInit() {
    this.reloadTableConfig();
  }

  $onChanges(changes) {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('product') && changes.product.previousValue.id !== changes.product.currentValue.id) {
      this.reloadTableConfig();

      this.printLoader.then(_ => {
        this.hasSomeQualifiedPrints = this.renderablePrints && this.renderablePrints.some(print => this.isPrintQualified(print));
      });
    }
  }

  isPrintQualified(print) {
    if(!_.get(this.product, print.requires, true)) {
      return false;
    }

    if(this.prints === null) {
      throw new Error('Prints not read');
    }

    return this.modalPrintPreviewService.isReprintAvailable(this.prints, print.code);
  }

  toPrettyCode(printCode) {
    return this.$filter('prettyEnum')(printCode.replace(/TERM_DEPOSIT_|DEPOSIT_ACCOUNT_/gi, ''));
  }

  reloadTableConfig() {
    this.tableBuilder.resetCheckedAndUncheckedOperations();
    if (this.product) {
      this.passbookHistoryConfig = this.tableBuilder.buildNgTable({
        productId: this.product.id,
        status: ['PROCESSED', 'REVERTED', 'REJECTED'],
        passbookOnly: true,
        printedOperationIds: this.passbookObj.printedOperationIds ?? [],
        includePrintedInPassbook: this.showPrinted,
        passbookId: this.passbookObj.id
      });
      if (this.passbookObj) {
        this.displayLine = 1;
        printService.getPrint({
          printCode: this.productGroup + '_PASSBOOK',
          parameters: {
            'PRODUCT_DEFINITION': this.product.definitionId
          }
        }).then(print => {
          this.maxDisplayLine = print.attributes.find(x => x.type == ("MULTILINE_LINE_COUNT")).value;
          let currentLine = this.passbookObj.nextLine-1;
          this.displayLine = currentLine % this.maxDisplayLine +1;
        })
      }
    }
  }

  getOperationCode(operation) {
    return this.operationCodeFormatter.format(operation, this.product.id);
  }

  updateCheckboxForPrinting(operation) {
    if (operation.markedForPrintingInPassbook) {
      this.tableBuilder.checkedOperations.push(operation.id);
      this.tableBuilder.uncheckedOperations =
          this.tableBuilder.uncheckedOperations.filter(id => id !== operation.id);
    } else {
      this.tableBuilder.checkedOperations =
          this.tableBuilder.checkedOperations.filter(id => id !== operation.id);
      this.tableBuilder.uncheckedOperations.push(operation.id);
    }
  }

  translate(operation) {
    const code = this.getOperationCode(operation);
    if(this.operationCodeFormatter.isCustomFeeApplyOnOperation(code)) {
      return operation.feeName;
    }
    return this.$filter('translate')(code, 'OPERATION_NAME');
  }

  transactionClicked(transaction, $event) {
    $event.stopPropagation();
    this.selectedTransactionId = transaction.id;
  }

  canPrint(operations) {
    return (operations || []).some(o => o.markedForPrintingInPassbook)
  }

  editNumber() {
    let productGroupPath;
    if (this.productGroup === 'TERM_DEPOSIT')
      productGroupPath = 'term-deposits';
    else if (this.productGroup === 'DEPOSIT_ACCOUNT')
      productGroupPath = 'accounts';
    this.$location.path(`/customer/${this.customerId}/${productGroupPath}/${this.product.id}/issue-passbook/edit`)
  }

  async printPassbook(data, printCode) {
    let operationIds = await this.consolidateSelectedOperationsForPrinting();
    const printProviderInput = {
      productId: this.product.id,
      operationIds: operationIds,
      startLine: this.displayLine
    };

    const passbook = this.passbookObj;
    const printedOperationIds = new Set(passbook.printedOperationIds || []);
    const reprintingOperation = operationIds.every(id => printedOperationIds.has(id));

    if(printCode === 'DEPOSIT_ACCOUNT_PASSBOOK' && reprintingOperation) {
        // this will automatically show print
        const result = await this.command.execute('ReprintDepositPassbook', {
          printInput: printProviderInput,
        }).toPromise();

        if (result && !result.approvalRequired) {
          this.$route.reload();
        }

        return;
    }

    const {printed} = await this.modalPrintPreviewService.showAsync({
      printDescription: {
        code: printCode,
        parameters: {
          'PRODUCT_DEFINITION': this.product.definitionId,
        }
      },
      printProviderInput,
    });

    if (printed) {
      this.tableBuilder.resetCheckedAndUncheckedOperations();
      // passbook can be printed from multiple places, so tagging operation as printed is in PrintService.handlePassbook
      this.$route.reload();
    }
  }

  async consolidateSelectedOperationsForPrinting() {
      let operations = await this.operationService.fetchOperationsByCriteria({
          params: {
              productIds: [this.product.id],
              fetchBalanceSnapshots: false,
              fetchAttributes: false,
              fetchPassbookOperationsOnly: true,
              passbookId: this.passbookObj.id,
              nonPrintedPassbookOperationsOnly: true,
              printedOperationIds: this.passbookObj.printedOperationIds ?? [],
              orderByTransactionId: true,
              ignorePagination: true,
              status: ['PROCESSED', 'REVERTED', 'REJECTED']
          }
      });
      let operationIds = operations.result
          .filter(o => !this.tableBuilder.uncheckedOperations.includes(o.id))
          .map(o => o.id);
      operationIds.push();
      return [...new Set([
        ...operationIds,
        ...this.tableBuilder.checkedOperations
      ])];
  }
}

nxModule.component('passbook', {
  templateUrl,
  bindings: {
    renderablePrints: '<',
    product: '<',
    productGroup: '<',
    customerId: '<',
    passbookObj: '=',
    displayLine: '<',
    maxDisplayLine: '<'
  },
  controller: Passbook
});
